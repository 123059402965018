import React from "react"

import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => {
  return (
    <DriveLayout title="Relief Drivers">
      <Hero title="Relief Drivers" />
      <Container size="medium">
        <Stack spacing="XLarge">
          <EmberCard title="Introduction">
            <EmberCardSection>
              <Stack direction="column">
                <Text>
                  Relief drivers make up an important part of the Ember team,
                  helping us to flex up and down our staffing levels to respond
                  to sickness, holidays and other demands. In return, we offer a
                  completely flexible working pattern where you can choose to
                  only accept shifts that fit around your other commitments.
                </Text>
                <Text>
                  This page sets out the main points about how we work with
                  relief drivers. If you have any questions, ask the Operations
                  Team in your chat room.
                </Text>
              </Stack>
            </EmberCardSection>
          </EmberCard>
          <EmberCard title="Availability and Other Preferences">
            <EmberCardSection>
              <Stack direction="column">
                <Text>
                  Availability for relief drivers will generally be requested by
                  the Operations Team at least two weeks in advance and collated
                  onto the availability spreadsheet. This can be reviewed on the
                  Driver Hub.
                </Text>
                <Text>
                  Sharing your rough availability helps us to have a better
                  understanding of when to contact you about a shift. It doesn't
                  commit you to anything.
                </Text>
                <Text>
                  We'll also check in with you regularly to make sure we
                  understand any other commitments you have, work or otherwise,
                  that may affect your availability and how often you wish to
                  drive with us. If your preferences shift at any time please
                  speak to the Operations Team via your chat room and we’ll make
                  the necessary adjustments.
                </Text>
                <Text>
                  As a relief driver, you will be expected to work a minimum of
                  one shift each month. This is to ensure you maintain your
                  knowledge levels and stay up to date on any company changes.
                  If you go more than a month without a shift you may be
                  requested to do additional training before being offered
                  shifts.
                </Text>
                <Text>
                  You can see all unassigned shifts in advance on the rota. If
                  there's a shift you'd be keen to cover, then contact the
                  Operations Team in your chat room.
                </Text>
              </Stack>
            </EmberCardSection>
          </EmberCard>
          <EmberCard title="Contact">
            <EmberCardSection>
              <Stack direction="column">
                <Text>
                  As a relief driver, you need to be in business essential
                  Google Chat rooms (Driver Announcements, Charger Alerts and
                  your own room) as well as have access to driver hub to view
                  shift activities.
                </Text>
                <Text>
                  The expectation will be that you have checked Driver
                  Announcements before the start of a shift to make sure you are
                  up to date on any changes which may affect you.
                </Text>
                <Text>
                  Charger Alerts is required so you can respond to charging
                  issues during your shift, such as a bus stopping charging.
                </Text>
                <Text>
                  Your own room is required since it will be your main point of
                  contact with the Operations Team.
                </Text>
                <Text>
                  You can mute alerts from Google Chat when you are unavailable
                  to work with Ember. Speak to a member of the Operations Team
                  if you’d like assistance with this.
                </Text>
              </Stack>
            </EmberCardSection>
          </EmberCard>
          <EmberCard title="Training and Return to Work Briefs">
            <EmberCardSection>
              <Stack direction="column">
                <Text>
                  If you have not driven with us for more than a month, you can
                  expect a return to work brief with a member of the Operations
                  Team upon agreeing a shift. This can be completed either by
                  phone or video call. The purpose of the call is to make sure
                  you are up to date on all procedures and changes which may
                  have changed since your last driving shift.
                </Text>
                <Text>
                  Any relief driver who has gone for a substantial period
                  without driving with us will need to complete a day of
                  refresher training before they can be allocated shifts in the
                  future.
                </Text>
                <Text>
                  This training will include a refresher on Google Chat, bus
                  operation and charging procedures.
                </Text>
              </Stack>
            </EmberCardSection>
          </EmberCard>
          <EmberCard title="Long Term Inactivity">
            <EmberCardSection>
              <Stack direction="column">
                <Text>
                  If you have gone more than three months without accepting a
                  driving shift with us, then following a conversation with the
                  Operations Team you may be removed from our systems.
                </Text>
                <Text>
                  This does not mean that you will never be contacted to cover
                  shifts but before you drive again you will need to be
                  reactivated on our systems and undergo refresher training.
                </Text>
              </Stack>
            </EmberCardSection>
          </EmberCard>
        </Stack>
      </Container>
    </DriveLayout>
  )
}

export default Page
